import React from 'react';
import ReactPlayer from 'react-player/youtube';

type Props = {
  url: String,
  width: String,
};

// eslint-disable-next-line react/prefer-stateless-function
class VideoPlayer extends React.Component<Props> {
  render() {
    const { url, width } = this.props;
    return (
      <div>
        <ReactPlayer
          className="react-player"
          url={url}
          key={url}
          width={width || '80%'}
          height="350px"
          controls
        />
      </div>
    );
  }
}

export default VideoPlayer;
