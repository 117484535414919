import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import getVideoId from 'get-video-id';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import CardActionArea from '@material-ui/core/CardActionArea';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import VideoPlayer from '../../Components/Organisations/Shared/VideoPlayer';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem 6rem',
  },
  rootInfo: {
    padding: theme.spacing(1, 1),
  },
  dateContent: {
    padding: '3px',
    display: 'inline-flex',
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    contain: 'layout',
  },
  headerTypo: {
    fontWeight: 600,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.45',
    margin: 7,
    marginLeft: 10,
  },
  dialog: {
    '&>*': {
      '&>*': {
        overflowY: 'initial',
      },
    },
  },
  title: {
    marginRight: 'auto',
    marginLeft: 'auto',
    margin: theme.spacing(4, 0),
  },
  subtitle: {
    margin: theme.spacing(2),
  },
}));

function Video() {
  const classes = useStyles();
  const [renderVideo, setRenderVideo] = React.useState(false);
  const [courseLink, setCourseLink] = React.useState(null);

  const courses = [
    // {
    //   id: 9,
    //   title:
    //     'Atelier: La gestion des troubles du comportement au quotidien intervention d’une psychologue',
    //   link: 'https://www.youtube.com/watch?v=muirqBPf248',
    // },
    // {
    //   id: 10,
    //   title: 'Atelier: Prendre soin de soi, la méditation de pleine conscience',
    //   link: 'https://www.youtube.com/watch?v=a5kj-VKX-iE',
    // },
    // {
    //   id: 11,
    //   title: 'Atelier: Les aidants salariés, intervention d’une psychologue',
    //   link: 'https://www.youtube.com/watch?v=DAx5sPRKtiw',
    // },
    // {
    //   id: 12,
    //   title: 'Atelier: “PRENDRE SOIN DE SOI” : La relaxation',
    //   link: 'https://www.youtube.com/watch?v=GUQ1S8AD3Xc',
    // },
    // {
    //   id: 13,
    //   title: 'Atelier: « Fils ou fille de », intervention d’une psychologue',
    //   link: 'https://www.youtube.com/watch?v=HExAA7cUpNU',
    // },
    // {
    //   id: 14,
    //   title: "Atelier: Présentation de l'offre de service des PFR et du site soutenirlesaidants.fr",
    //   link: 'https://www.youtube.com/watch?v=FB_7pxpwwAk',
    // },
    // {
    //   id: 15,
    //   title: 'Atelier: Présentation du projet OPULSE',
    //   link: 'https://www.youtube.com/watch?v=y_bX-15B33M',
    // },
    // {
    //   id: 14,
    //   title: "Atelier: L'alimentation du binôme aidant / aidé par Silver Fourchette",
    //   link: 'https://www.youtube.com/watch?v=ejgmAe_FGCA',
    // },
    // {
    //   id: 15,
    //   title: '[JRA] Plénière 2 : Les politiques en faveur des aidants',
    //   link: 'https://www.youtube.com/watch?v=5u5kHznTgRk',
    // },
    // {
    //   id: 16,
    //   title: '[JRA] Plénière 3 : Le répit focus sur des propositions déployées sur notre région',
    //   link: 'https://www.youtube.com/watch?v=oexUtYMk1dk',
    // },
    // {
    //   id: 17,
    //   title: '[JRA] Plénière 4 : La santé des aidants : comment prévenir l’épuisement ?',
    //   link: 'https://www.youtube.com/watch?v=doixrZ5fagc',
    // },
    // {
    //   id: 18,
    //   title: "Discours d'ouverture",
    //   link: 'https://youtu.be/dCCbXbtC-yE',
    // },
    // {
    //   id: 19,
    //   title: "Le parcours d'accompagnement des aidants ",
    //   link: 'https://youtu.be/TbcQFUbtgBQ',
    // },
    // {
    //   id: 20,
    //   title: 'Les jeunes aidants',
    //   link: 'https://youtu.be/rix_rNNe_Y4',
    // },
    // {
    //   id: 21,
    //   title: "Comment optimiser l'accompagnement des aidants",
    //   link: 'https://youtu.be/3771_HcjiuQ',
    // },
    // {
    //   id: 22,
    //   title: 'Financement et accès aux droits',
    //   link: 'https://youtu.be/tAqi3UGG5EU',
    // },
    // {
    //   id: 23,
    //   title: 'Synthèse et clôture',
    //   link: 'https://youtu.be/KM3DhgwVOhY',
    // },
    {
      id: 24,
      title: "Discours d'ouverture",
      link: 'https://youtu.be/HqSnLwa-xm4',
    },
    {
      id: 25,
      title: 'Plénière 1 : Constat pluridisciplinaire sur la santé des aidants',
      link: 'https://youtu.be/v1kqRWXXEf0',
    },
    {
      id: 26,
      title: 'Ateliers de la matinée',
      link: 'https://youtu.be/fVjH3O_F1Vk',
    },
    {
      id: 27,
      title: 'Plénière 2 : Mieux organiser le parcours de santé des aidants',
      link: 'https://youtu.be/wtA_ExExOIM',
    },
    {
      id: 28,
      title: "Ateliers de l'après-midi",
      link: 'https://youtu.be/Gpml8VJ2S6c',
    },
    {
      id: 29,
      title: "Remise des prix : Appel à projet FED'R'AIDANTS",
      link: 'https://youtu.be/UjvfDoU4YQM',
    },
  ];

  const handleClick = link => {
    setRenderVideo(true);
    setCourseLink(link);
  };

  return (
    <>
      <Grid container className={classes.layout}>
        <Grid align="center" className={classes.title}>
          <Typography variant="h2">Retour sur la Journée Nationale des Aidants</Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            12 octobre 2023
          </Typography>
        </Grid>
        {courses?.map(course => (
          <Grid item md={3} key={course.link} className={classes.rootInfo}>
            <Card style={{ width: '100%', height: '100%' }}>
              <CardActionArea onClick={() => handleClick(course.link)}>
                <CardMedia
                  className={classes.media}
                  image={
                    course.link && `https://img.youtube.com/vi/${getVideoId(course.link).id}/0.jpg`
                  }
                  title={course.title}
                />

                <Grid>
                  <CardContent className={classes.dateContent}>
                    <Typography variant="h6" component="div" className={classes.headerTypo}>
                      {course.title}
                    </Typography>
                  </CardContent>
                </Grid>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={renderVideo}
        onClose={() => setRenderVideo(false)}
        fullWidth
        className={classes.dialog}
      >
        <VideoPlayer url={courseLink} width="100%" />
      </Dialog>
    </>
  );
}

export default Video;
